<template>
  <!-- 转派回写 -->
  <div class="Assign" v-loading="loading">
    <div class="BBg" :style="{ height: isheight }" @click="outwuye"></div>
    <div class="flex_between pb10 bbe9s">
      <div class="title f-left p10">任务信息2</div>
      <!-- <div class="flex_start">
        <el-button type="success">外呼店长</el-button>
        <el-button type="warning">外呼客户</el-button>
      </div>-->
    </div>
    <!-- 录入信息 -->
    <div class="flex_warp">
      <!-- 客户姓名 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">客户姓名</span>
        <el-input v-model="Customer" placeholder="请输入客户姓名"></el-input>
      </div>
      <!-- 联系方式 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">联系方式</span>
        <el-input v-model="phone" placeholder="请输入联系方式"></el-input>
      </div>
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">下单时间</span>
        <el-date-picker
          :clearable="true"
          v-model="xiadantime"
          type="datetime"
          placeholder="选择下单时间"
        ></el-date-picker>
      </div>
      <!-- 小区名称 -->
      <div class="blocks2 flex_items flex_warp mt10">
        <div class="flex_start flex_items mr30">
          <span class="mr20">小区名称</span>
          <el-input v-model="House" placeholder="请输入小区名称"></el-input>
        </div>
      </div>
    </div>
    <!-- 客户地址 -->
    <div class="blocks2 flex_items flex_start addres">
      <span class="mr20">客户地址</span>
      <div class="flex_start">
        <el-select
          v-model="oneplace"
          placeholder="选择省"
          @change="choseplace(oneplace, '1', 'hand')"
          class="mr10 mt10 quyu"
          :disabled="dis"
        >
          <el-option
            v-for="item in place1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="twoplace"
          placeholder="选择市"
          @change="choseplace(twoplace, '2', 'hand', 'handtwo')"
          class="mr10 mt10 quyu"
        >
          <el-option
            v-for="item in place2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="threeplace"
          placeholder="选择区/县"
          @change="choseplace(threeplace, '3', 'hand', 'handthree')"
          class="mr10 mt10 quyu"
        >
          <el-option
            v-for="item in place3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          class="mt10 quyu"
          v-model="menpai"
          placeholder="请输入门牌号"
          style="width: 117px"
        ></el-input>
      </div>
    </div>
    <div class="blocks2 flex_items flex_start">
      <span class="mr20">详细地址</span>
      <el-input
        class="mt10 quyu mr10"
        v-model="detailedplace"
        placeholder="请输入详细地址"
        style="width: 400px"
      ></el-input>
    </div>
    <!-- 订单来源 -->
    <div class="blocks2 flex_items flex_start">
      <span class="mr20">订单来源</span>
      <el-select
        v-model="onewhere"
        placeholder="一级来源"
        @change="chosewhere(onewhere)"
        class="mr10"
      >
        <el-option
          v-for="item in sources"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        ></el-option>
      </el-select>
      <!-- twowhere != '' -->
      <el-select
        v-if="sources2.length != 0"
        v-model="twowhere"
        placeholder="二级来源"
        @change="chosewhere1(twowhere)"
      >
        <el-option
          v-for="item in sources2"
          :key="item.id"
          :label="item.title"
          :value="item.title"
        ></el-option>
      </el-select>
    </div>

    <!-- v-if="states == 0" -->
    <div class="blocks2 flex_items flex_start">
      <span class="mr20">房屋问题</span>
      <div class="flex_start" v-if="states == 0">
        <el-select
          v-model="onequestion"
          placeholder="一级问题"
          @change="choseques(onequestion, '1', 'hand')"
          class="mr10 mt10"
        >
          <el-option
            v-for="item in question1"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="twoquestion"
          placeholder="二级问题"
          @change="choseques(twoquestion, '2', 'hand', 'handtwo')"
          class="mr10 mt10"
        >
          <el-option
            v-for="item in question2"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="threequestion"
          placeholder="三级问题"
          @change="choseques(threequestion, '3', 'hand', 'handthree')"
          class="mr10 mt10"
        >
          <el-option
            v-for="item in question3"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div v-else class="flex_start">
        <el-select
          v-model="onequestion"
          placeholder="一级问题"
          @change="choseques(onequestion, '1', 'hand')"
          class="mr10 mt10"
          disabled
        >
          <el-option
            v-for="item in question1"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="twoquestion"
          placeholder="二级问题"
          @change="choseques(twoquestion, '2', 'hand', 'handtwo')"
          class="mr10 mt10"
          disabled
        >
          <el-option
            v-for="item in question2"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="threequestion"
          placeholder="三级问题"
          @change="choseques(threequestion, '3', 'hand', 'handthree')"
          class="mr10 mt10"
          disabled
        >
          <el-option
            v-for="item in question3"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>

    <!-- 物业 -->
    <div
      class="blocks2 flex_items flex_warp"
      style="overflow: inherit !important"
    >
      <div class="flex_start flex_items pos mr20 mt10">
        <span class="mr20">选择物业</span>
        <el-input
          v-model="wuyeVal"
          placeholder="请选择物业"
          @focus="getwuye"
          style="width: 220px"
        ></el-input>
        <ul
          v-if="wuyekuang && NewItems.length != 0"
          class="abs wuyekuang"
          style="width: 221px; left: 75px"
        >
          <li
            v-for="(item, index) in NewItems"
            :key="index"
            :value="item.group_id"
            v-text="item.username"
            @click="chosewuye(index)"
          ></li>
        </ul>
        <p
          class="zanwu"
          v-if="NewItems.length == 0 && nowuye"
          @click="chosezanwu"
        >
          --暂无数据--
        </p>
      </div>
      <!-- 推荐姓名 -->
      <div class="flex_items flex_start mr20 mt10">
        <span class="mr20">推荐姓名</span>
        <el-input
          v-model="Recommend"
          placeholder="请输入推荐姓名"
          style="width: 220px"
        ></el-input>
      </div>
    </div>
    <!-- 意向店长 -->
    <div v-if="yixiang != null" class="flex_items flex_start mr20 mt20">
      <span class="mr20">意向店长</span>
      <el-input
        v-model="yixiang"
        disabled="disabled"
        style="width: 220px"
      ></el-input>
    </div>
    <!-- 指派店长 -->
    <div v-if="kind != 'bianji'" class="flex_items flex_start mt20 pos">
      <span class="mr20">指派店长</span>
      <el-input
        v-model="shopper"
        placeholder="选择指派店长"
        @focus="getdian"
        style="margin-right: 10px"
      ></el-input>
      <div
        v-if="diankuang && NewItems2.length != 0"
        class="abs diankuang"
        style="width: 88%; left: 75px"
      >
        <el-table :data="NewItems2" border @row-click="chosedian">
          <el-table-column
            prop="usernamestorename"
            label="店长"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="work_tag"
            label="标 签"
            min-width="150px"
          ></el-table-column>
          <el-table-column prop="turnovers" label="成交率(%)"></el-table-column>
          <el-table-column prop="fraction" label="分数"></el-table-column>
          <el-table-column prop="distance" label="距离(km)"></el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state == 1" type="success">在线</el-tag>
              <el-tag v-else type="danger">离线</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="orderCount"
            label="今日已接单数"
          ></el-table-column>
          <el-table-column
            prop="punch_clock"
            label="今日上门"
            min-width="170px"
          >
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.punch_clock" :key="index">
                {{ item }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="estimate" label="今日打卡" min-width="170px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.estimate" :key="index">
                {{ item }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="当前位置" min-width="170px">
            <template slot-scope="scope">
              <el-button type="success" @click="viewLocation(scope.row)"
                >查看位置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <p
        class="zanwudian"
        v-if="NewItems2.length == 0 && nodiankuang"
        @click="chosezanwu"
      >
        --暂无数据--
      </p>
    </div>
    <!-- 备注说明 -->
    <div class="flex_start flex_items mt20">
      <span class="mr20">备注说明</span>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入备注"
        v-model="textarea"
      ></el-input>
    </div>
    <!-- 图片上传 -->
    <div class="blocks2 flex_items flex_start">
      <span class="mr20">问题图片</span>
      <el-upload
        drag
        action="https://public.yiniao.co/api/v1/upload"
        list-type="picture-card"
        :multiple="mutrue"
        :on-preview="handlePictureCardPreview"
        :on-success="upsuccess"
        :file-list="dialogImageUrlArray"
        :data="tuzhi"
        :on-remove="afterRemove"
      >
        <i class="el-icon-plus"></i>
      </el-upload>

      <el-dialog :visible.sync="tudiag" :modal="false">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </div>
    <!-- 是否发送短信 -->
    <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
      <span class="mr20">发送短信</span>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="店长"></el-checkbox>
        <el-checkbox label="客户"></el-checkbox>
      </el-checkbox-group>
    </div>
    <!-- 筛选 -->
    <div class="blocks2 pb30 bbe9s" v-if="kind == 'assign'">
      <el-button
        type="primary"
        style="margin-right: 30px"
        @click="addsub('yes')"
        >指派订单</el-button
      >
      <el-button @click="addsub('no')">保存无效订单</el-button>
    </div>
    <div class="blocks2 pb30 bbe9s" v-else>
      <el-button
        type="primary"
        @click="addsub('bianji')"
        style="margin: 30px auto; display: flex"
        >提交编辑</el-button
      >
    </div>
  </div>
</template>
<script>
if (!Array.prototype.some) {
  Array.prototype.some = function (fun /*, thisp*/) {
    var len = this.length;
    if (typeof fun != "function") throw new TypeError();
    var thisp = arguments[1];
    for (var i = 0; i < len; i++) {
      if (i in this && fun.call(thisp, this[i], i, this)) return true;
    }
    return false;
  };
}
export default {
  props: ["currentPage3"],
  data() {
    return {
      isheight: "",
      Customer: "",
      xiadantime: "",
      phone: "",
      details: {
        pro_id: "",
        pro_id1: "",
        pro_id2: "",
        province_id: "",
        city_id: "",
        county_id: "",
        state: "是",
        states: 0,
        stat: "",
        stat1: "",
        stat2: "",
      },
      //   获取地址
      place1: [],
      place2: [],
      place3: [],
      oneplace: "",
      twoplace: "",
      threeplace: "",
      detailedplace: "",
      House: "",
      diandianid: "",
      dis: false,
      ooid: "",
      //   房屋问题
      question1: [],
      question2: [],
      question3: [],
      onequestion: "",
      twoquestion: "",
      threequestion: "",
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      //   推荐人
      Recommend: "",
      // 店长
      shoppers: [],
      shopper: "",
      shopperid: "",
      textarea: "",
      diankuang: false,
      nodiankuang: false,
      // 图片上传
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      dialogImageUrlArray: [],
      // 订单来源
      sources: [],
      sources2: [],
      onewhere: "",
      twowhere: "",
      loading: true,
      menpai: "",
      // 是否发送短信
      checkList: ["店长", "客户"],
      kind: "",
      yixiang: "",
      states: "",
      problems: [],
      problemArr: [],
      radio: 0,
      problems1: [],
      street: "",
      building: "",
      unit: "",
      room: "",
    };
  },
  mounted() {
    this.kind = sessionStorage.getItem("kind");
    this.getplace(0, "1", "1");
    this.getwhere();
    this.getData();
  },
  methods: {
    // 查看店长位置
    viewLocation(row) {
      console.log("当前row", row);
      let userId = row.user_id;
      let routeData = this.$router.resolve({
        query: { userId: userId },
        path: "/mapLocation",
      });
      window.open(routeData.href, "_blank");
    },

    afterRemove(file, fileList) {
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == file.response.data.file.path) {
          this.imgs.splice(i);
        }
      }
    },
    getData() {
      this.util
        .get(this.HOST + "/Order/info", {
          order_id: this.$store.state.order_id,
          // order_id: "510100010000027106",
          Colloquialism: 4,
        })
        .then((res) => {
          if (res.code == 200) {
            this.Customer = res.data.contacts;
            this.phone = res.data.telephone;
            this.xiadantime = res.data.created_time;
            this.detailedplace = res.data.addres;
            this.onewhere = res.data.channel;
            this.twowhere = res.data.channelTitle;
            this.Recommend = res.data.management;
            this.textarea = res.data.remarks;
            this.wuyeVal = res.data.content;
            this.shopperid = res.data.user_id;
            this.shopper = res.data.usernamestorename;
            this.yixiang = res.data.intention;
            this.House = res.data.residential_quarters;
            this.states = res.data.state;
            this.getwhere();
            // 二级来源
            // if (res.data.channelTitle != "" && res.data.channelTitle != null) {
            //   for (let i = 0; i < this.sources.length; i++) {
            //     if (res.data.channel_id == this.sources[i].id) {
            // this.sources2 = this.sources[i].data;
            //       this.sources2.push(this.sources[i].data)
            //     }
            //   }
            // }
            // 物业
            for (let i = 0; i < this.items.length; i++) {
              if (res.data.content == this.items[i].username) {
                this.wuyeid = this.items[i].group_id;
              }
            }
            if (res.data.logo != null) {
              for (let i = 0; i < res.data.logo.length; i++) {
                this.dialogImageUrlArray.push({
                  url: res.data.logo[i],
                });
              }
            }
            // 地址
            this.oneplace = res.data.province;
            this.twoplace = res.data.city;
            this.threeplace = res.data.county;
            this.details.city_id = res.data.city_old_id;
            this.ooid = res.data.city_id;
            this.details.county_id = res.data.county_old_id;
            //console.log(this.place1, res.data.province_id, "assdsf");
            for (let i = 0; i < this.place1.length; i++) {
              if (this.place1[i].old_id == res.data.province_id) {
                this.details.province_id = this.place1[i].id;
                // this.details.province_id = this.place1[i].old_id;
                this.getplace22(this.details.province_id, "1", "2");
                break;
              }
            }
            // 房屋问题
            this.details.pro_id = res.data.pro_id;
            this.details.pro_id1 = res.data.pro_id1;
            this.details.pro_id2 = res.data.pro_id2;
            for (let i = 0; i < this.question1.length; i++) {
              if (res.data.pro_id == this.question1[i].id) {
                this.onequestion = this.question1[i].title;
                this.getquestion22(res.data.pro_id, "1");
                break;
              }
            }
            if (this.oneplace != null) {
              this.dis = true;
            }
            if (res.data.city_id != null) {
              this.shop("aa");
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取地址
    getplace(place_id, times, types, hand, hand2) {
      this.util
        .get(this.HOST + "/Common/province", {
          city_id: place_id,
          structures: types,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              if (!hand) {
                this.oneplace = res.data.data[0].name;
                this.details.province_id = res.data.data[0].id;
                this.place1 = res.data.data;
                this.getplace(res.data.data[0].id, "2", "2");
              } else {
                this.getplace(this.details.province_id, "2", "2", "hand");
              }
            } else if (times == "2") {
              if (!hand) {
                // this.twoplace = res.data.data[0].name;
                // this.details.city_id = res.data.data[0].id;
                this.place2 = res.data.data;
                this.getplace(res.data.data[0].id, "3", "3");
                this.diandianid = res.data.data[0].old_id;
                this.shop();
              } else {
                if (!hand2) {
                  // this.twoplace = res.data.data[0].name;
                  // this.details.city_id = res.data.data[0].id;
                  this.diandianid = res.data.data[0].old_id;
                  this.place2 = res.data.data;
                  this.shop("aa");
                } else {
                  //console.log(res.data.data, "3333333333333333333");
                  this.place3 = res.data.data;
                  // -------获取判断店长
                  for (let i = 0; i < this.place2.length; i++) {
                    if (this.place2[i].id == this.details.city_id) {
                      this.diandianid = this.place2[i].old_id;
                    }
                  }
                  this.shop("aa");
                }
                this.getplace(this.details.city_id, "3", "3", "hand");
              }
            } else {
              this.place3 = res.data.data;
              //console.log(res.data.data, "plaaaaaaaaaaaaa");
              if (hand) {
                // this.threeplace = res.data.data[0].name;
                this.details.county_id = res.data.data[0].id;
              } else {
                // this.threeplace = res.data.data[0].name;
                this.details.county_id = res.data.data[0].id;
              }
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
          // this.getproblems(this.diandianid)
        });
    },
    // this.getplace22(this.details.province_id, "1", "2")
    getplace22(place_id, times, types) {
      this.util
        .get(this.HOST + "/Common/province", {
          city_id: place_id,
          structures: types,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              this.place2 = res.data.data;
              this.diandianid = res.data.data[0].old_id;
              this.getplace22(this.details.city_id, "2", "2");
            } else if (times == "2") {
              this.place3 = res.data.data;
            } else {
              this.place3 = res.data.data;
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseplace(place_id, times, hand, hand2) {
      if (times == "1") {
        this.details.province_id = place_id;
        this.getplace(place_id, times, "1", hand);
        this.getquestion(0, "1", false, false, "aa");
      } else if (times == "2") {
        this.details.city_id = place_id;
        if (hand2) {
          this.getplace(place_id, times, "2", hand, hand2);
        } else {
          this.getplace(place_id, times, "2", hand);
        }
      } else {
        this.details.county_id = place_id;
      }
    },
    // 获取问题
    getquestion(parent_id, times, hand, hand2, aa) {
      let shengid = "";
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      this.util
        .get(this.HOST + "/Common/goodsCategory", {
          parent_id: parent_id,
          city_id: shengid,
          //uu: shengid,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              if (!hand) {
                this.onequestion = res.data[0].title;
                this.question1 = res.data;
                this.details.pro_id = res.data[0].id;
                this.getquestion(res.data[0].id, "2", false, false, aa);
              } else {
                this.getquestion(this.details.pro_id, "2", "hand", false, aa);
              }
            } else if (times == "2") {
              if (!hand) {
                this.twoquestion = res.data[0].title;
                this.question2 = res.data;
                this.details.pro_id1 = res.data[0].id;
                this.getquestion(res.data[0].id, "3", false, false, aa);
              } else {
                if (!hand2) {
                  this.twoquestion = res.data[0].title;
                  this.details.pro_id1 = res.data[0].id;
                  this.question2 = res.data;
                } else {
                  this.question3 = res.data;
                }
                this.getquestion(this.details.pro_id1, "3", "hand", false, aa);
              }
            } else {
              this.question3 = res.data;
              if (hand) {
                this.threequestion = res.data[0].title;
                this.details.pro_id2 = res.data[0].id;
              } else {
                this.details.pro_id2 = res.data[0].id;
                this.threequestion = res.data[0].title;
                this.getwhere(aa);
              }
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 回写问题
    getquestion22(parent_id, times) {
      let shengid = "";
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      this.util
        .get(this.HOST + "/Common/goodsCategory", {
          parent_id: parent_id,
          city_id: shengid,
          //uu: shengid,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              this.question2 = res.data;
              for (let i = 0; i < res.data.length; i++) {
                if (this.details.pro_id1 == res.data[i].id) {
                  this.twoquestion = res.data[i].title;
                  break;
                }
              }
              this.getquestion22(this.details.pro_id1, "2");
            } else if (times == "2") {
              this.question3 = res.data;
              for (let i = 0; i < res.data.length; i++) {
                if (this.details.pro_id2 == res.data[i].id) {
                  this.threequestion = res.data[i].title;
                  break;
                }
              }
            } else {
              this.question3 = res.data;
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseques(parent_id, times, hand, hand2) {
      if (times == "1") {
        this.details.pro_id = parent_id;
        this.getquestion(parent_id, times, hand);
      } else if (times == "2") {
        this.details.pro_id1 = parent_id;
        if (hand2) {
          this.getquestion(parent_id, times, hand, hand2);
        } else {
          this.getquestion(parent_id, times, hand);
        }
      } else {
        this.details.pro_id2 = parent_id;
      }
    },
    // 获取物业
    wuyes(aa) {
      let shengid = "";
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      this.util
        .get(this.HOST + "/Common/residential", {
          city_id: shengid,
          //uu: shengid,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.items = res.data;
            if (!aa) {
              this.getData();
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长
    shop(aa) {
      let shiid = "";
      if (this.ooid != "" && this.ooid != null) {
        shiid = this.ooid;
      } else {
        for (let i = 0; i < this.place2.length; i++) {
          if (this.place2[i].id == this.details.city_id) {
            shiid = this.place2[i].old_id;
          }
        }
      }
      this.util
        .get(this.HOST + "/Common/user", {
          // city_id: shiid,
          city_id: this.diandianid,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            if (!aa) {
              this.getquestion(0, "1", false, false, aa);
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 订单来源
    getwhere(aa) {
      let shengid = "";
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      if (shengid) {
        this.util
          .get(this.HOST + "/Common/chanel", {
            city_id: shengid,
            //uu: shengid,
          })
          .then((res) => {
            if (res.code == 200) {
              this.sources = res.data;
              this.wuyes(shengid);
              for (let j = 0; j < this.sources.length; j++) {
                if (this.onewhere == this.sources[j].title) {
                  this.sources2 = this.sources[j].data;
                }
              }
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    chosewhere(idx) {
      this.sources2 = [];
      this.twowhere = "";
      for (let i = 0; i < this.sources.length; i++) {
        if (this.sources[i].id == idx) {
          this.sources2 = this.sources[i].data;
        }
      }
    },
    chosewhere1(twowhere) {
      // this.sources2 = [];
      // for (let i = 0; i < this.sources.length; i++) {
      //   if (this.sources[i].data.id == twowhere) {
      //     this.sources2 = this.sources[i].data;
      //   }
      // }
    },
    // 店长
    getdian(event) {
      if (this.shopper == null) {
        this.shopper = "";
      }
      this.diankuang = true;
      this.nodiankuang = true;
      this.isheight = this.util.gaodu() + "px";
    },
    chosedian(row) {
      this.shopperid = row.user_id;
      this.shopper = row.usernamestorename;
      this.diankuang = false;
      this.nodiankuang = false;
      this.isheight = "0px";
    },
    // 物业
    getwuye(event) {
      if (this.wuyeVal == null) {
        this.wuyeVal = "";
      }
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    outwuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.diankuang = false;
      this.nodiankuang = false;
      this.isheight = "0px";
    },
    chosewuye(idx, event) {
      this.wuyeid = this.NewItems[idx].group_id;
      this.wuyeVal = this.NewItems[idx].username;
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    closewuye() {
      this.wuyekuang = false;
      this.isheight = "0px";
      this.nowuye = false;
    },
    chosezanwu() {
      this.nowuye = false;
      this.nodiankuang = false;
    },
    // 图片上传
    uploadFile(file) {
      // //console.log(file);
      this.formDate.append(file, file.file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      // //console.log(response);
      this.imgs.push(response.data.file.path);
    },
    addsub(no, x) {
      let text = "指派中...";
      if (no == "no") {
        text = "保存中...";
      } else if (no == "bianji") {
        text = "更新中...";
      } else {
        if (this.oneplace == null) {
          this.$message({
            showClose: true,
            message: "请选择省市区",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.shopper == "" || this.shopper == null) {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择指派店长!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      }
      if (this.util.datatime(this.xiadantime) == "NaN-NaN-NaN NaN:NaN:NaN") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入下单时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.Customer == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入客户姓名!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.phone == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入客户联系电话!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.onewhere == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择订单来源!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.sources2.length > 0 && this.twowhere == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择二级订单来源!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.detailedplace == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入详细地址!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      // this.$loading({
      //   fullscreen: true,
      //   background: "rgba(0, 0, 0, 0.7)",
      //   text: text,
      // });
      if (this.dialogImageUrlArray.length > 0) {
        for (let i = 0; i < this.dialogImageUrlArray.length; i++) {
          this.imgs.push(this.dialogImageUrlArray[i].url);
        }
      }
      if (this.detailedplace != "") {
        // 打包后
        this.$jsonp(this.HOST2, {
          key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
          region: this.twoplace,
          region_fix: 1,
          keyword: this.detailedplace,
          output: "jsonp",
        }).then((res) => {
          if (res.status == 0) {
            if (res.count == 0) {
              this.$message({
                showClose: true,
                type: "error",
                message: "无相关详细地址,请准确输入!",
                duration: 2000,
                offset: 90,
              });
              this.$loading().close();
            } else {
              this.Assign(
                res.data[0].location.lat,
                res.data[0].location.lng,
                no
              );
            }
          }
        });
      } else {
        this.Assign("", "", no);
      }
    },
    // 提交
    Assign(lat, lng, no) {
      let shengid = "";
      let shiid = "";
      let quid = "";
      let state = 1;
      let timem = this.util.datatime(this.xiadantime);
      let message = "订单指派成功!";
      let sms = 0;
      let sms1 = 0;
      if (no == "no") {
        state = 10;
        message = "订单保存成功!";
      }
      if (no == "bianji") {
        message = "订单更新成功!";
      }
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      for (let i = 0; i < this.place2.length; i++) {
        if (this.place2[i].id == this.details.city_id) {
          shiid = this.place2[i].old_id;
        }
      }
      for (let i = 0; i < this.place3.length; i++) {
        if (this.place3[i].id == this.details.county_id) {
          quid = this.place3[i].old_id;
        }
      }
      for (let i = 0; i < this.checkList.length; i++) {
        if (this.checkList[i] != "店长") {
          sms = 1;
        }
        if (this.checkList[i] != "客户") {
          sms1 = 1;
        }
      }
      if (typeof this.onewhere == "string") {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].title == this.onewhere) {
            this.onewhere = this.sources[i].id;
          }
        }
      }
      for (let i = 0; i < this.shoppers.length; i++) {
        if (this.shopper == this.shoppers[i].usernamestorename) {
          this.shopperid = this.shoppers[i].user_id;
        }
      }
      let mm = "";
      if (this.sources2.length != 0) {
        for (let i = 0; i < this.sources2.length; i++) {
          if (this.sources2[i].title == this.twowhere) {
            mm = this.sources2[i].id;
            break;
          }
        }
      }
      this.place3.map((item) => {
        if (this.threeplace == item.name) {
          quid = item.old_id;
        }
      });
      // return false
      this.util
        .post(this.HOST + "/Order/Transfer", {
          pro_id: this.details.pro_id,
          pro_id1: this.details.pro_id1,
          pro_id2: this.details.pro_id2,
          // pro_id: 0,
          // pro_id1: 0,
          // pro_id2: 0,
          province: shengid,
          city: shiid,
          county: quid,
          addres: this.detailedplace + this.menpai,
          user_id: this.shopperid,
          contacts: this.Customer,
          telephone: this.phone,
          remarks: this.textarea,
          logo: JSON.stringify(this.imgs)
            .replace(/\[|]/g, "")
            .replace(/\"/g, ""),
          lat: lat,
          lng: lng,
          residential_id: this.wuyeid,
          channel_id: this.onewhere,
          channel_details: mm,
          management: this.Recommend,
          residential_quarters: this.House,
          state: state,
          created_time: timem,
          order_id: this.$store.state.order_id,
          cc_id: this.$store.state.cc_id,
          sms1: sms1,
          sms: sms,
          type: 1,
          problemArr: JSON.stringify(this.problemArr),
          street: this.street,
          building: this.building,
          unit: this.unit,
          room: this.room,
        })
        .then((res) => {
          // //console.log(res);
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: message,
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  // 物业
  computed: {
    NewItems() {
      var _this = this;
      var NewItems = [];
      this.items.map(function (item) {
        if (item.username.search(_this.wuyeVal) != -1) {
          NewItems.push(item);
        }
      });
      return NewItems;
    },
    // 店长
    NewItems2() {
      var _this = this;
      var NewItems2 = [];
      this.shoppers.map(function (item) {
        if (item.usernamestorename.search(_this.shopper) != -1) {
          NewItems2.push(item);
        }
      });
      return NewItems2;
    },
    getStoreItem() {
      return this.$store.state.dialogTableVisible;
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 16px;
  /* background: #545c64; */
  /* color: white; */
  width: 120px;
  cursor: pointer;
}
.quyu {
  width: 130px;
}
.ltitle {
  width: 65px;
}
/* 物业 */
.wuyekuang {
  top: 50px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.wuyekuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.wuyekuang li:hover {
  background-color: #f5f7fa;
}
li {
  list-style-type: none;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 50px;
  left: 76px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.el-textarea__inner {
  width: 400px;
  height: 96px;
}
.el-upload-list__item-preview {
  display: none !important;
}
.el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
}
.el-input__icon {
  line-height: 25px !important;
}
.el-date-editor .el-range-separator {
  line-height: 22px !important;
}
.el-button {
  padding: 8px 20px !important;
}
.el-table td,
.el-table th {
  padding: 5px 0 !important;
}
.el-form-item--feedback {
  margin: 12px auto !important;
}
.el-textarea {
  width: 400px !important;
}
</style>
